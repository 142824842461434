import { Modal, ControlledPopup, Button } from 'dyl-components';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import EventForm from 'shared/EventForm';
import eventsActions from 'actions/events';

const AddEventModal = ({ open, onClose, onSave, onEdit, onDeleteEvent, eventBeingEdited, isAllowedToModify, selected_users, control, watch, isValid, isDirty, trigger, setValue, getValues, reset, resetField, displayAllContacts = false, setNotificationContactName, accountContacts, currentContact }) => {
    const event_labels = useSelector((state) => state.events.event_labels.map(({ id, name }) => ({ key: id, value: id, text: name })));

    const dispatch = useDispatch();
    
    useEffect(() => {
        dispatch(eventsActions.readEventLabels());
    }, [dispatch])

    return (
        <Modal open={open} onClose={onClose}>
            <Modal.Header>{eventBeingEdited?.name ? eventBeingEdited.name : "New Event"}</Modal.Header>
            <Modal.Content scrolling>
                <EventForm
                    isModal
                    isAllowedToModify={isAllowedToModify}
                    isSaving={false}
                    event_labels={event_labels || []}
                    selected_users={selected_users}
                    selected_contacts={[]}
                    control={control}
                    watch={watch}
                    isValid={isValid}
                    isDirty={isDirty}
                    trigger={trigger}
                    setValue={setValue}
                    getValues={getValues}
                    reset={reset}
                    resetField={resetField}
                    eventBeingEdited={eventBeingEdited}
                    setNotificationContactName={setNotificationContactName}
                    displayAllContacts={displayAllContacts}
                    {...(!displayAllContacts ? {
                        contacts: (()=>{
                            if (accountContacts?.length === 0) {
                                return [currentContact]
                            }
                            return accountContacts
                        })()
                    } : {})}
                    toolbarClassName={"modal_toolbar_name"}
                />
            </Modal.Content>
            <Modal.Actions hasSaveButton onSave={eventBeingEdited?.id ? onEdit : onSave} saveDisabled={!isValid || !isDirty} >
                {eventBeingEdited?.id && (
                    <ControlledPopup
                        trigger={<Button status={"cancel"} basic disabled={!isAllowedToModify}>Delete</Button>}
                        onConfirm={onDeleteEvent}
                    />
                )}
            </Modal.Actions>
        </Modal>
    )
}

export default AddEventModal;